import {
    ChangeEvent,
    FC,
    FocusEventHandler,
    KeyboardEventHandler,
    useEffect,
    useRef
} from 'react'
import classNames from 'classnames'
import styles from 'styles/components/DataEntry/TextEntry/TextArea.module.sass'

type TextAreaProps = {
    disabled: boolean
    name: string
    value: string
    onChange: (value: string) => void
    onChangeRaw: (event: ChangeEvent<HTMLTextAreaElement>) => void
    placeholder: string
    onBlur?: FocusEventHandler
    onFocus?: FocusEventHandler
    oneLine?: boolean
    maxHeight?: string
    onKeyUp?: KeyboardEventHandler<HTMLTextAreaElement>
    onKeyDown?: KeyboardEventHandler<HTMLTextAreaElement>
    autoFocus?: boolean
}

const TextArea: FC<TextAreaProps> = ({
    placeholder,
    disabled,
    name,
    value,
    onChange,
    onChangeRaw,
    onBlur,
    onFocus,
    oneLine,
    maxHeight = '60vh',
    onKeyUp,
    onKeyDown,
    autoFocus
}) => {
    const textAreaRef = useRef<HTMLTextAreaElement>(null)

    const resizeTextArea = () => {
        const textArea = textAreaRef.current
        if (!textArea) return

        textArea.style.height = 'inherit'

        const scrollHeight = textArea.scrollHeight

        textArea.style.height = `min(${scrollHeight}px, ${maxHeight})`
    }

    useEffect(() => resizeTextArea())

    return (
        <textarea
            className={classNames(styles.textarea, {
                [styles.disabled ?? '']: disabled,
                [styles.oneLine ?? '']: oneLine
            })}
            ref={textAreaRef}
            placeholder={placeholder}
            aria-label={placeholder}
            disabled={disabled}
            name={name}
            value={value}
            onChange={e => {
                onChange(e.target.value)
                onChangeRaw(e)

                resizeTextArea()
            }}
            onBlur={onBlur}
            onFocus={onFocus}
            rows={oneLine ? 1 : undefined}
            onKeyUp={onKeyUp}
            onKeyDown={onKeyDown}
            autoFocus={autoFocus}
        />
    )
}

export default TextArea
