import classNames from 'classnames'
import Tag from 'components/Personal/FirstSection/Tag'
import { append, dropLast, F, last, map } from 'ramda'
import { noop } from 'ramda-adjunct'
import {
    ChangeEvent,
    FC,
    FocusEventHandler,
    useEffect,
    useRef,
    useState
} from 'react'
import styles from 'styles/components/DataEntry/TextEntry/TextArea.module.sass'

type TagInputProps = {
    name?: string
    value?: string
    onChange?: (value: string) => void
    onChangeRaw?: (event: ChangeEvent<HTMLInputElement>) => void
    onFocus?: FocusEventHandler
    onBlur?: FocusEventHandler
}

const TagInput: FC<TagInputProps> = ({
    name,
    value: initialValue = '',
    onChange,
    onChangeRaw,
    onFocus,
    onBlur
}) => {
    const [tags, setTags] = useState<string[]>(initialValue.split(','))
    const [value, setValue] = useState('')
    const inputRef = useRef<HTMLInputElement>(null)

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        let newValue = tags.join(',')
        const inputValue = e.target.value === ',' ? '' : e.target.value
        setValue(inputValue)

        if (inputValue !== '') {
            newValue += `,${inputValue}`
        }
        onChange?.(newValue)
        e.target.value = newValue
        onChangeRaw?.(e)
    }

    useEffect(() => {
        const input = inputRef.current
        if (!input) return

        const handleKeyDown = (e: KeyboardEvent) => {
            if (value === '' && e.key === 'Backspace') {
                const lastTag = last(tags) ?? ''
                setTags(dropLast<string>(1))
                setValue(lastTag)
            }

            if (value !== '' && e.key === ',') {
                setTags(append(value))
                setValue('')
            }
        }

        input.addEventListener('keydown', handleKeyDown)
        return () => input.removeEventListener('keydown', handleKeyDown)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    const mapTags = map((tag: string) => (
        <Tag key={tag} className="mb-1" text={tag} />
    ))

    return (
        <div
            className={classNames(
                styles.textarea,
                'flex flex-wrap [&>:not(:last-child)]:mr-1'
            )}
        >
            {mapTags(tags)}
            <input
                className="h-min grow pb-4 outline-none"
                ref={inputRef}
                name={name}
                value={value}
                onChange={handleOnChange}
                onFocus={onFocus}
                onBlur={onBlur}
            />
        </div>
    )
}

export default TagInput
