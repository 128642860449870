import {
    ChangeEvent,
    FC,
    FocusEventHandler,
    HTMLInputTypeAttribute,
    KeyboardEventHandler,
    useEffect,
    useRef,
    useState
} from 'react'
import ShowPasswordButton from './ShowPasswordButton'
import styles from 'styles/components/DataEntry/TextEntry/TextInput.module.sass'
import classNames from 'classnames'

type TextInputProps = {
    disabled: boolean
    name: string
    value: string
    password: boolean
    onChange: (value: string) => void
    onChangeRaw: (event: ChangeEvent<HTMLInputElement>) => void
    placeholder: string
    iconsPresent: boolean
    autoFill?:
        | 'new-password'
        | 'current-password'
        | 'email'
        | 'city-area-region'
    onBlur?: FocusEventHandler
    onFocus?: FocusEventHandler
    type?: HTMLInputTypeAttribute
    onKeyUp?: KeyboardEventHandler<HTMLInputElement>
    onKeyDown?: KeyboardEventHandler<HTMLInputElement>
    autoFocus?: boolean
    focused?: boolean
    setFocused?: (value: boolean) => void
    mouseOnAutocomplete?: boolean
}

const TextInput: FC<TextInputProps> = ({
    placeholder,
    disabled,
    password,
    name,
    value,
    onChange,
    onBlur,
    onFocus,
    onChangeRaw,
    iconsPresent,
    autoFill,
    type,
    onKeyDown,
    onKeyUp,
    autoFocus,
    focused = false,
    setFocused = () => {},
    mouseOnAutocomplete
}) => {
    const [isTextShown, setTextShown] = useState(password)

    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (inputRef.current && !focused) inputRef.current.blur()
    }, [focused])

    return (
        <>
            <input
                ref={inputRef}
                className={classNames(styles.input, {
                    [styles.withoutIcons ?? '']: !password && !iconsPresent
                })}
                type={isTextShown ? 'password' : type || 'text'}
                name={name}
                value={value}
                onFocus={e => {
                    setFocused(true)
                    onFocus && onFocus(e)
                }}
                onBlur={e => {
                    !mouseOnAutocomplete && setFocused(false)
                    onBlur && onBlur(e)
                }}
                onChange={event => {
                    onChange(event.target.value)
                    onChangeRaw(event)
                }}
                disabled={disabled}
                placeholder={placeholder}
                aria-label={placeholder}
                autoComplete={autoFill}
                enterKeyHint={'send'}
                onKeyUp={onKeyUp}
                onKeyDown={onKeyDown}
                autoFocus={autoFocus}
            />
            {password && !disabled && (
                <ShowPasswordButton
                    active={isTextShown}
                    focused={focused}
                    onFocus={() => setFocused(true)}
                    onBlur={() => !mouseOnAutocomplete && setFocused(false)}
                    onClick={() => setTextShown(!isTextShown)}
                />
            )}
        </>
    )
}

export default TextInput
