import { FC } from 'react'
import classNames from 'classnames'
import styles from '../../../styles/components/Personal/FirstSection/Tag.module.sass'

type TagProps = {
    text: string
    type?: 'light' | 'mid' | 'dark' | 'symbiosis'
    className?: string
}

const Tag: FC<TagProps> = ({ text, type = 'mid', className }) => (
    <div className={classNames(styles.tag, styles[type], className)}>
        {text}
    </div>
)

export default Tag
