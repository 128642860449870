import { ComponentProps, FC, PropsWithChildren } from 'react'
import classNames from 'classnames'
import styles from '../../../styles/components/DataEntry/TextEntry.module.sass'
import AutocompleteBox from './AutocompleteBox'
import TextEntry from '../TextEntry'
import { isNotNilOrEmpty } from 'ramda-adjunct'

const Container: FC<
    PropsWithChildren<{
        focused: boolean
        setFocused: (value: boolean) => void
        mouseOnAutocomplete: boolean
        setMouseOnAutocomplete: (value: boolean) => void
        props: ComponentProps<typeof TextEntry>
    }>
> = ({
    children,
    props,
    focused,
    setFocused,
    mouseOnAutocomplete,
    setMouseOnAutocomplete
}) => {
    const {
        tagsInput,
        textArea,
        value = '',
        bounded,
        invalidEntryMessage,
        disabled
    } = props

    const suggestions =
        !textArea && !tagsInput && props.autocompleteProvider
            ? props.autocompleteProvider.suggestions
            : null

    const shouldShowAutocomplete =
        !textArea && !tagsInput && focused && isNotNilOrEmpty(suggestions)

    const isIconsPresent = props.infoIcons && props.infoIcons[0]

    return (
        <div
            className={classNames(styles.div, {
                [styles.bounded ?? '']: bounded
            })}
        >
            <div
                className={classNames(styles.divDiv, {
                    [styles.focused ?? '']: focused,
                    [styles.invalid ?? '']: !!invalidEntryMessage,
                    [styles.disabled ?? '']: disabled,
                    [styles.withIcon ?? '']:
                        !textArea && !tagsInput
                            ? props.password || isIconsPresent
                            : isIconsPresent
                })}
                onFocus={() => setFocused(true)}
                onBlur={() => !mouseOnAutocomplete && setFocused(false)}
            >
                {children}
            </div>
            {!disabled && Boolean(invalidEntryMessage) && (
                <p className={styles.p}>{invalidEntryMessage}</p>
            )}
            {shouldShowAutocomplete && (
                <AutocompleteBox
                    onMouseEnter={() => setMouseOnAutocomplete(true)}
                    onMouseLeave={() => setMouseOnAutocomplete(false)}
                    query={value}
                    selectValue={value => {
                        props?.autocompleteProvider?.onSelect?.(value)
                        setFocused(false)
                        setMouseOnAutocomplete(false)
                    }}
                    suggestions={suggestions || []}
                />
            )}
        </div>
    )
}

export default Container
