import { FC } from 'react'
import Icon from '../../Icon'
import classNames from 'classnames'
import styles from 'styles/components/DataEntry/TextEntry/ShowPasswordButton.module.sass'

type ShowPasswordButtonProps = {
    active: boolean
    onClick: () => void
    focused?: boolean
    onFocus?: () => void
    onBlur?: () => void
}

const ShowPasswordButton: FC<ShowPasswordButtonProps> = ({
    active,
    onClick,
    focused,
    onFocus,
    onBlur
}) => (
    <button
        type="button"
        className={styles.icon}
        onClick={onClick}
        onFocus={onFocus}
        onBlur={onBlur}
    >
        <Icon
            className={classNames(styles.icon, {
                [styles.focused ?? '']: focused
            })}
            name={active ? 'eye' : 'eye-invisible'}
        />
    </button>
)

export default ShowPasswordButton
