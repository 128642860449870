import { FC, useEffect, useState } from 'react'
import Markdown from '../../Markdown'
import styles from 'styles/components/DataEntry/TextEntry/AutocompleteBox.module.sass'
import classNames from 'classnames'

type AutocompleteBoxProps = {
    query: string
    suggestions: string[]
    selectValue?: (value: string) => void
    onMouseEnter?: () => void
    onMouseLeave?: () => void
}

const AutocompleteBox: FC<AutocompleteBoxProps> = ({
    query,
    suggestions,
    selectValue = () => {},
    onMouseEnter = () => {},
    onMouseLeave = () => {}
}) => {
    let [focusedItemIndex, setFocusedItemIndex] = useState(0)

    useEffect(() => {
        const maxSize = suggestions.length - 1

        const keyHandler: Record<string, (e: KeyboardEvent) => void> = {
            ArrowUp: () =>
                setFocusedItemIndex(value =>
                    value - 1 === -1 ? maxSize : value - 1
                ),

            ArrowDown: () =>
                setFocusedItemIndex(value =>
                    value + 1 === maxSize + 1 ? 0 : value + 1
                ),

            Home: () => setFocusedItemIndex(0),
            PageUp: () => setFocusedItemIndex(0),

            End: () => setFocusedItemIndex(maxSize),
            PageDown: () => setFocusedItemIndex(maxSize),

            Enter: () => selectValue(suggestions[focusedItemIndex] ?? ''),
            Tab: () => selectValue(suggestions[focusedItemIndex] ?? '')
        }

        const handleKey = (e: KeyboardEvent) => {
            if (keyHandler[e.key]) {
                e.preventDefault()
                keyHandler[e.key]?.(e)
            }
        }

        window.addEventListener('keydown', handleKey)
        return () => window.removeEventListener('keydown', handleKey)
    })

    return (
        <div
            className={styles.container}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {suggestions.map((suggestion, i) => (
                <div
                    key={suggestion}
                    className={classNames(styles.item, {
                        [styles.focusedItem ?? '']: focusedItemIndex === i
                    })}
                    onClick={() => selectValue(suggestion)}
                    onMouseEnter={() => setFocusedItemIndex(i)}
                >
                    <Markdown>
                        {suggestion.replace(
                            new RegExp(query.trim(), 'i'),
                            match => (match ? `**${match}**` : '')
                        )}
                    </Markdown>
                </div>
            ))}
        </div>
    )
}

export default AutocompleteBox
