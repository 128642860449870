import {
    ChangeEvent,
    ComponentProps,
    FC,
    FocusEventHandler,
    KeyboardEventHandler,
    useEffect,
    useState
} from 'react'
import TextInput from './TextEntry/TextInput'
import TextArea from './TextEntry/TextArea'
import classNames from 'classnames'
import styles from 'styles/components/DataEntry/TextEntry.module.sass'
import Icon, { IconProps } from '../Icon'
import Container from './TextEntry/Container'
import IconButton from '../Layout/Header/IconButton'
import { isNotNil } from 'ramda-adjunct'
import TagInput from './TextEntry/TagInput'

type TextEntryProps = {
    children: string
    disabled?: boolean
    bounded?: boolean
    invalidEntryMessage?: string
    name?: string
    value?: string
    onChange?: (value: string) => void
    onBlur?: FocusEventHandler
    onFocus?: FocusEventHandler
    infoIcons?: (
        | (IconProps & { onClick?: undefined })
        | (IconProps & { onClick: () => void; label: string })
    )[]
    autoFocus?: boolean
}

export type AutocompleteProvider = {
    query: (query: string) => void
    suggestions: string[]
    onSelect?: (value: string) => void
}

type TextEntryInputProps = TextEntryProps & {
    tagsInput?: false
    textArea?: false
    password?: boolean
    autoFill?: ComponentProps<typeof TextInput>['autoFill']
    autocompleteProvider?: AutocompleteProvider
    onChangeRaw?: (event: ChangeEvent<HTMLInputElement>) => void
    type?: ComponentProps<typeof TextInput>['type']
    onKeyUp?: KeyboardEventHandler<HTMLInputElement>
    onKeyDown?: KeyboardEventHandler<HTMLInputElement>
}

type TextEntryAreaProps = TextEntryProps & {
    tagsInput?: false
    textArea: true
    oneLine?: boolean
    onChangeRaw?: (event: ChangeEvent<HTMLTextAreaElement>) => void
    maxHeight?: string
    onKeyUp?: KeyboardEventHandler<HTMLTextAreaElement>
    onKeyDown?: KeyboardEventHandler<HTMLTextAreaElement>
}

type TextEntryTagsProps = TextEntryProps & {
    textArea?: false
    tagsInput: true
    onChangeRaw?: (event: ChangeEvent<HTMLInputElement>) => void
    onKeyUp?: KeyboardEventHandler<HTMLInputElement>
    onKeyDown?: KeyboardEventHandler<HTMLInputElement>
}

const TextEntry: FC<
    TextEntryInputProps | TextEntryAreaProps | TextEntryTagsProps
> = props => {
    const {
        infoIcons = [],
        children,
        disabled = false,
        name = '',
        value = '',
        onChange = () => {},
        onBlur,
        onFocus,
        textArea,
        tagsInput,
        onKeyUp,
        onKeyDown,
        autoFocus
    } = props

    const renderedInfoIcons = infoIcons.map((icon, i) =>
        icon.onClick !== undefined ? (
            <IconButton
                key={i}
                className={classNames(styles.icon, icon.className)}
                name={icon.name}
                label={icon.label}
                onClick={icon.onClick}
            />
        ) : (
            <Icon
                key={i}
                className={classNames(styles.icon, icon.className)}
                name={icon.name}
            />
        )
    )

    const onChangeHandler = (value: string) => {
        onChange(value)

        if (
            !props.textArea &&
            !props.tagsInput &&
            isNotNil(props.autocompleteProvider)
        ) {
            props.autocompleteProvider.query(value)
        }
    }

    const [focused, setFocused] = useState(false)
    const [mouseOnAutocomplete, setMouseOnAutocomplete] = useState(false)

    useEffect(() => {
        const keyHandler: Record<string, (e: KeyboardEvent) => void> = {
            Escape: () => focused && setFocused(false)
        }

        const handleKey = (e: KeyboardEvent) => {
            if (keyHandler[e.key]) {
                e.preventDefault()
                keyHandler[e.key]?.(e)
            }
        }

        window.addEventListener('keydown', handleKey)
        return () => window.removeEventListener('keydown', handleKey)
    })

    if (tagsInput) {
        const { onChangeRaw = () => {} } = props

        return (
            <Container
                props={props}
                focused={focused}
                setFocused={setFocused}
                mouseOnAutocomplete={mouseOnAutocomplete}
                setMouseOnAutocomplete={setMouseOnAutocomplete}
            >
                <TagInput
                    name={name}
                    value={value}
                    onChange={onChange}
                    onChangeRaw={onChangeRaw}
                    onFocus={onFocus}
                    onBlur={onBlur}
                />
            </Container>
        )
    }

    if (textArea) {
        const { onChangeRaw = () => {}, oneLine, maxHeight } = props

        return (
            <Container
                props={props}
                focused={focused}
                setFocused={setFocused}
                mouseOnAutocomplete={mouseOnAutocomplete}
                setMouseOnAutocomplete={setMouseOnAutocomplete}
            >
                <TextArea
                    placeholder={children}
                    disabled={disabled}
                    name={name}
                    value={value}
                    onChange={onChangeHandler}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    onChangeRaw={onChangeRaw}
                    oneLine={oneLine}
                    maxHeight={maxHeight}
                    onKeyDown={onKeyDown}
                    onKeyUp={onKeyUp}
                    autoFocus={autoFocus}
                />
                {renderedInfoIcons}
            </Container>
        )
    }

    const { password = false, onChangeRaw = () => {}, autoFill, type } = props

    return (
        <Container
            props={props}
            focused={focused}
            setFocused={setFocused}
            mouseOnAutocomplete={mouseOnAutocomplete}
            setMouseOnAutocomplete={setMouseOnAutocomplete}
        >
            <TextInput
                mouseOnAutocomplete={mouseOnAutocomplete}
                focused={focused}
                setFocused={setFocused}
                placeholder={children}
                disabled={disabled}
                password={password}
                iconsPresent={Boolean(infoIcons[0])}
                name={name}
                value={value}
                onChange={onChangeHandler}
                onBlur={onBlur}
                onFocus={onFocus}
                onChangeRaw={onChangeRaw}
                autoFill={autoFill}
                type={type}
                onKeyDown={onKeyDown}
                onKeyUp={onKeyUp}
                autoFocus={autoFocus}
            />
            {renderedInfoIcons}
        </Container>
    )
}

export default TextEntry
